import { MoneyAccountType } from '@dltru/dfa-models'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { VuesaxIcon, Wallet } from '../../../Icons'
import { Space } from '../../../Space'
import { Button, CopyButton } from '../../../button'
import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'
import style from './style.m.less'

export interface IMoneyNominalAccountProps {
    balanceInfo?: Partial<Pick<MoneyAccountType, 'nominal_account' | 'created_at' | 'number'>>
    hideEmpty?: boolean
    changeAction?: () => void
    addAction?: () => void
}

export const MoneyNominalAccount: FC<IMoneyNominalAccountProps> = ({
    balanceInfo,
    hideEmpty,
    changeAction,
    addAction,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const createdDateStr = balanceInfo?.created_at
        ? dayjs.unix(balanceInfo.created_at).format(VIEW_DATE_FORMAT)
        : EMPTY_FIELD_VALUE

    if (!balanceInfo?.nominal_account && !balanceInfo?.number && hideEmpty) {
        return null
    }
    const wrapperClass = clsx(style.infoWidgetBordered, {
        [style.full]: isOpen,
    })
    const nominalAccountClass = clsx(style.iwLabelWrapper, {
        [style.smallGray]: !isOpen,
        [style.red]: !isOpen && !balanceInfo?.nominal_account,
    })
    const isShowDetails = balanceInfo?.nominal_account || changeAction

    return (
        <div className={style.container}>
            <div
                className={wrapperClass}
                onMouseEnter={isShowDetails ? () => setIsOpen(true) : undefined}
                onMouseLeave={isShowDetails ? () => setIsOpen(false) : undefined}
            >
                <div className={style.iwLabelWrapper}>
                    <div className={style.iwLabelIcon}>
                        <Wallet />
                    </div>
                    <div className={style.iwLabel}>
                        {balanceInfo?.number ?? 'Лицевой счёт'}
                        {isOpen && balanceInfo?.number && (
                            <>
                                &nbsp;
                                <CopyButton text={String(balanceInfo.number)} />
                            </>
                        )}
                    </div>
                </div>
                {isOpen && (
                    <div className={style.iwValue}>
                        {balanceInfo?.number ? (
                            <>
                                Номер лицевого счёта в системе <br /> открыт {createdDateStr}
                            </>
                        ) : (
                            <>Будет открыт после регистрации пользователя</>
                        )}
                    </div>
                )}
                {balanceInfo?.nominal_account ? (
                    <>
                        <div className={nominalAccountClass}>
                            <div className={style.iwLabelIcon}>
                                <VuesaxIcon />
                            </div>
                            <div className={style.iwLabel}>
                                {balanceInfo.nominal_account.account_details.account_number}
                                {isOpen &&
                                    balanceInfo.nominal_account.account_details.account_number && (
                                        <>
                                            &nbsp;
                                            <CopyButton
                                                text={String(
                                                    balanceInfo.nominal_account.account_details
                                                        .account_number,
                                                )}
                                            />
                                        </>
                                    )}
                            </div>
                        </div>
                        {isOpen && (
                            <>
                                <div className={style.iwValue}>
                                    Номер банковского счёта, привязанный к лицевому счёту
                                </div>
                                {changeAction && (
                                    <Button type="link" width="110px" onClick={changeAction}>
                                        Изменить
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {changeAction ? (
                            <>
                                <div className={nominalAccountClass}>
                                    <div className={style.iwLabelIcon}>
                                        <VuesaxIcon />
                                    </div>
                                    <strong>Банковский счёт не привязан</strong>
                                </div>
                                {isOpen && (
                                    <Space size={0}>
                                        <Button type="link" width="110px" onClick={changeAction}>
                                            Привязать
                                        </Button>
                                        {addAction && (
                                            <Button type="link" width="220px" onClick={addAction}>
                                                Создать новый банковский счёт
                                            </Button>
                                        )}
                                    </Space>
                                )}
                            </>
                        ) : (
                            <div className={style.iwValue}>Открыт {createdDateStr}</div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
